let resumeData = {
  imagebaseurl: "https://abisheshshakya.com.np",
  name: "Abishesh Shakya",
  role: "ENT surgeon & HNS",
  linkedinId: "abishesh-shakya-5b9898122",
  skypeid: "abishesh.shakya",
  roleDescription: "",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/abishesh-shakya-5b9898122/",
      className: "fa fa-linkedin"
    },
    {
      name: "facebook",
      url: "https://www.facebook.com/abishesh.shakya",
      className: "fa fa-facebook"
    },
    {
      name: "twitter",
      url: "https://twitter.com/abishesh1234511",
      className: "fa fa-twitter"
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/abishesh_shakya/",
      className: "fa fa-instagram"
    }
  ],
  aboutme:
    "Dr. Abishesh Shakya is an ENT/Otorhinolaryngologist in Nepal. He completed MBBS from Kathmandu University and Masters in Surgery from Tribhuvan University.\n" +
    "He is a member of Nepal Medical Council and Nepal Medical Association.",
  services: [
    "Fracture Nasal Bone Correction",
    "Speech Threrapy",
    "Throat and Voice Problems",
    "Nasal Septum Surgery and Nasal Endoscopy, etc.",
    "Hearing Aid",
    "Tonsillectomy",
    "Adenoidectomy",
    "Ear Drum Repair",
    "Thyroid Surgery",
    "Salivary Gland Surgery",
    "Mastoidectomy",
    "OSA (Snoring Problem)",
    "Vertigo (Dizziness) treatment "
  ],
  address: "Lalitpur, Nepal",
  email: "contact@abisheshshakya.com.np",
  phoneNo: "+977 981-720-4666",
  education: [
    {
      UniversityName: "Tribhuvan University",
      specialization: "Otorhinolaryngologist",
      MonthOfPassing: "March",
      YearOfPassing: "2019"
      // "Achievements":"Some Achievements"
    },
    {
      UniversityName: "Kathmandu University",
      specialization: "MBBS",
      MonthOfPassing: "March",
      YearOfPassing: "2013"
      // "Achievements":"Some Achievements"
    }
  ],
  work: [
    {
      CompanyName: "Patan Academy of Health Science",
      specialization: undefined,
      MonthOfLeaving: "Left on April",
      YearOfLeaving: "2016"
      // "Achievements":"Some Achievements"
    },
    {
      CompanyName: "Civil Service Hospital",
      specialization: "Ms ENT Registrar",
      MonthOfLeaving: "April",
      YearOfLeaving: "2019",
      Days: "Monday, Thursday, Friday",
      Timing: "3:00pm - 5:00pm"
      // "Achievements":"Some Achievements"
    },
    {
      CompanyName: "Global Hospital",
      specialization: "Consultant",
      MonthOfLeaving: "April",
      YearOfLeaving: "2019",
      Days: "Sunday, Tuesday, Wednesday",
      Timing: "3:00pm - 5:00pm"
      // "Achievements":"Some Achievements"
    },
    {
      CompanyName: "KIST Hospital",
      specialization: "Lecturer",
      MonthOfLeaving: "April",
      YearOfLeaving: "2019",
      Days: "Everyday",
      Timing: "9:00am - 3:00pm"
      // "Achievements":"Some Achievements"
    }
    // {
    //   "CompanyName":"Some Company",
    //   "specialization":"Some specialization",
    //   "MonthOfLeaving":"Jan",
    //   "YearOfLeaving":"2018",
    //   "Achievements":"Some Achievements"
    // }
  ],
  skillsDescription: "",
  skills: [
    {
      skillname: "Tonsillectomy"
    },
    {
      skillname: "Myringoplasty"
    },
    {
      skillname: "Septoplasty"
    }
  ],
  portfolio: [
    {
      name: "Earthquake camps",
      description: "Free camps in dolakha, Kavre"
      // "imgurl":"images/portfolio/phone.jpg"
    }
    // {
    //   "name":"project2",
    //   "description":"mobileapp",
    //   "imgurl":"images/portfolio/project.jpg"
    // },
    // {
    //   "name":"project3",
    //   "description":"mobileapp",
    //   "imgurl":"images/portfolio/project2.png"
    // },
    // {
    //   "name":"project4",
    //   "description":"mobileapp",
    //   "imgurl":"images/portfolio/phone.jpg"
    // }
  ],
  testimonials: [
    // {
    //   "description":"This is a sample testimonial",
    //   "name":"Some technical guy"
    // },
    // {
    //   "description":"This is a sample testimonial",
    //   "name":"Some technical guy"
    // }
  ]
};

export default resumeData;
