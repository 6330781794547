import React, { Component } from "react";
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpg" alt="" />
          </div>

          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p style={{ whiteSpace: "pre-line", color: "#838C95" }}>
              {resumeData.aboutme}
            </p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Services Provided</h2>
                <ul
                  style={{
                    listStyleType: "disc",
                    listStylePosition: "inside"
                  }}
                >
                  {resumeData.services.map(service => (
                    <li>
                      <span className="address">{service}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <span className="address">
                  <a
                    target="_top"
                    href={`mailto:${resumeData.email}?Subject=Hello%20There`}
                  >
                    <i className="fa fa-inbox" />
                    &nbsp;&nbsp;
                    {resumeData.email}
                  </a>
                  <br />
                  <a
                    target="_top"
                    href={`tel:${resumeData.phoneNo.replace(/[^\d+]/g, "")}`}
                  >
                    <i className="fa fa-phone" />
                    &nbsp;&nbsp;
                    {resumeData.phoneNo}
                  </a>
                  <br />
                  <a
                    target="_blank"
                    href={`http://maps.google.com/?q=${resumeData.address}`}
                  >
                    <i className="fa fa-map-marker" />
                    &nbsp;&nbsp;
                    {resumeData.address}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
